.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.App > video {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: auto;
  position: absolute;
  max-width: 1980px;
  z-index: 1;
}

.App > div {
  display: flex;
  height: 33%;
  align-items: center;
}

.App-socials-grid {
  height: 33%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;
  z-index: 2;
}

.App-socials-grid > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-socials-grid > div > a,
.App-socials-grid > div > button {
  width: 48px;
  text-align: center;
}

.App-socials-grid > div > button {
  background-color: transparent;
  border-style: none;
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .App-socials-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 32px;
  }

  .App-socials-grid > div > a,
  .App-socials-grid > div > button {
    width: 32px;
  }
}
